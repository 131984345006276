<template>
  <df-modal
    persistent
    width="594"
    :action-name="$t('DiseasesControl.overwrite_sprayings_modal.action')"
    :loading="loading"
    :title="$t('DiseasesControl.overwrite_sprayings_modal.title')"
    @action-click="overwriteSprayings"
    @close="closeOverwriteSprayingsModal"
  >
    <template #card-content>
      <div class="df-flex-xl df-flex-col overwrite-sprayings-modal">
        <v-expansion-panels
          v-for="(sprayings, sowingId) in sprayingsBySowing"
          :key="sowingId"
          accordion
          flat
          multiple
          class="df-flex-sm"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="panel__header">
              <h3>
                {{ $t('DiseasesControl.overwrite_sprayings_modal.sowing_on') }}
                {{ sowingToSprayingMap[sowingId].formatDate() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="panel__content">
              <div
                v-for="spraying in sprayings"
                :key="spraying.id"
                class="df-col-6 df-flex-m df-col-12-m align-center flex-nowrap spraying-card"
              >
                <div class="df-flex-sm df-flex-col card__infos">
                  <h4>
                    {{
                      $t(
                        'DiseasesControl.overwrite_sprayings_modal.spraying_on'
                      )
                    }}
                    {{ sprayingDate(spraying) }}
                  </h4>
                  <div class="df-flex-m align-center">
                    <p>({{ spraying.spraying_order }}º)</p>
                    <div class="df-flex-xm df-flex-col">
                      <p v-for="product in spraying.products" :key="product.id">
                        {{ product.name }} {{ productPrice(product) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="df-flex-sm flex-nowrap modal__warning">
          <font-awesome-icon color="#F2CD12" icon="exclamation-triangle" />
          <span>
            {{ $t('DiseasesControl.overwrite_sprayings_modal.warning') }}
          </span>
        </div>
      </div>
    </template>
  </df-modal>
</template>

<script>
import DfModal from '@/lib/Modal/DfModal.vue'

export default {
  name: 'OverwriteSprayingsModal',

  props: {
    sowings: {
      required: true,
      type: Array,
    },
    sprayings: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      loading: false,
      sowingToSprayingMap: {},
      sprayingsBySowing: {},
    }
  },

  components: {
    DfModal,
  },

  created() {
    this.sowingToSprayingMap = this.sowings.reduce((acc, sowing) => {
      acc[sowing.id] = sowing.to_sow_date
      return acc
    }, {})
    this.sprayingsBySowing = this.sprayings.reduce((acc, spraying) => {
      if (Object.keys(acc).includes(String(spraying.sowing_id))) {
        acc[spraying.sowing_id].push(spraying)
      } else {
        acc[spraying.sowing_id] = [spraying]
      }
      return acc
    }, {})
  },

  methods: {
    closeOverwriteSprayingsModal() {
      this.$emit('close')
    },
    overwriteSprayings() {
      this.loading = true
      this.$emit('action-click')
      this.loading = false
    },
    productPrice(product) {
      return this.$currency(
        (product.price_per_liter * product.dose_per_hectare).toFixed(2)
      )
    },
    sprayingDate(spraying) {
      const currentDate = spraying.applied_date || spraying.ideal_date
      const date = new Date(currentDate)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear()).slice(-2)

      return `${day}/${month}/${year}`
    },
  },
}
</script>

<style lang="scss" scoped>
.overwrite-sprayings-modal {
  .panel__header {
    border-radius: 8px;
    border: 1px solid #e6e9e6 !important;
    padding: 16px 12px;

    h3 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .panel__content {
    padding: 12px !important;
    background: #f8f9f8;

    .spraying-card {
      padding: 12px 16px;
      border-radius: 4px;
      border: 1px solid #e6e9e6;
      background: #fff;

      .card__infos {
        height: fit-content;

        h4 {
          color: #1a2b46;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
        p {
          color: #5b6459;
          font-family: 'Rubik';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
  .modal__warning {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #fff2b4;
    background-color: #fffdf5;

    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
::v-deep .v-expansion-panel-header--active {
  border-radius: 8px 8px 0px 0px !important;
  min-height: 60px;
}
::v-deep .v-expansion-panel-header__icon {
  margin: 0px;

  & .v-icon {
    color: #5b6459 !important;
  }
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>
